import { CustomField } from '../../../_model/custom-field.model';

const layout = {
    header: {
        // Header links config
        headerLinks: {
            show: true,
            position: 'fixed',
            left: [
                new CustomField({ value: '<a href="mailto:info@omnicasa.com" title="Mail"><i class="fa-envelope"></i> info@omnicasa.com</a>', prepend: '', append: '', html: true, tooltip: '', show: true }),
                new CustomField({ value: '<a href="tel:1234567890" title="Phone"><i class="fa-phone"></i> 1234.567.890</a>', prepend: '', append: '', html: true, tooltip: '', show: true }),
            ],
            right: [
                new CustomField({ value: '<a href="javascript:void(0)" title="" style="color: #3B5998"><i class="fab fa-facebook-official"></i></a>', prepend: '', append: '', html: true, tooltip: '', show: true }),
                new CustomField({ value: '<a href="javascript:void(0)" title="" style="color: #1DA1F2"><i class="fab fa-twitter"></i></a>', prepend: '', append: '', html: true, tooltip: '', show: true }),
                new CustomField({ value: '<a href="javascript:void(0)" title=""><i class="fab fa-instagram" style="background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf); border-radius: 5px"></i></a>', prepend: '', append: '', html: true, tooltip: '', show: true }),
            ],
        },
        appBar: {
            changeOnScroll: true,
        },
        banner: {
            show: false,
            showLogo: true,
            type: 'image',
            title: {
                nl: '',
                fr: '',
                en: '',
                public: false,
            },
            description: {
                nl: '',
                fr: '',
                en: '',
                public: false
            },
            image: {
                url: 'https://cloud-storage.omnicasa.com/public/{CUSTOMER_NAME_2007}/static-uploads/0f8df340-a936-11e9-86d0-e5f8f316c0c0.jpeg',
            },
            gallery: {
                config: {
                    autoplay: true,
                    dots: false,
                    arrows: false,
                    infinite: true,
                    fade: true,
                    speed: 3000,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
                images: [
                    {
                        id: 7,
                        filename: '56fbb520-9192-11e9-8d4a-6de7231c99c6',
                        originalname: 'main-img-min.jpg',
                        destination: 'static-uploads/56fbb520-9192-11e9-8d4a-6de7231c99c6.jpeg',
                        mimetype: 'image/jpeg',
                        url: 'https://cloud-storage.omnicasa.com/public/{CUSTOMER_NAME_2007}/static-uploads/56fbb520-9192-11e9-8d4a-6de7231c99c6.jpeg',
                        createDate: '2019-06-18T06:28:52.210Z'
                    },
                    {
                        id: 8,
                        filename: '',
                        originalname: '',
                        destination: 'static-uploads/56fbb520-9192-11e9-8d4a-6de7231c99c6.jpeg',
                        mimetype: 'image/jpeg',
                        url: 'https://cloud-storage.omnicasa.com/public/{CUSTOMER_NAME_2007}/static-uploads/63ed24b0-b42b-11e9-921a-f3b260dd9b02.jpeg',
                        createDate: '2019-06-18T06:28:52.210Z'
                    }
                ]
            },
            video: [{
                url: 'https://omnicasaexternalfiles.omnicasaweb.com/4474-c21berthouts2019/vid2018.mp4',
                type: 'video/mp4'
            }, {
                url: 'https://omnicasaexternalfiles.omnicasaweb.com/4474-c21berthouts2019/vid2018.ogv',
                type: 'video/ogg'
            }]
        },
        menu: {
            position: 'center',
            showFreeEvaluation: true,
            showHomeMenu: true,
            color: '#ffffff',
        },
        menuMobile: {
            position: 'left',
            backgroundColor: '#ffffff',
            color: '#000000',
            iconColor: '#ffffff'
        },
        languageSwitcher: {
            position: 'right',
        },
        logo: {
            position: 'left',
            showLogoAtStart: true,
            image: {
                url: 'https://www.omnicasa.com/images/logo.svg'
            }
        },
        propertySearch: {
            show: true,
            showSearchInput: true,
            showSearchButton: true,
            showCountProperty: true,
            text: {
                nl: '',
                fr: '',
                en: ''
            },
            data: {
                countProperty: [0, 0]
            },
            showSearchCount: true,
        }
    },
    footer: {
        bottom: [
            {
                id: 1,
                span: 5,
                title: {
                    nl: '',
                    fr: '',
                    en: '',
                    public: false
                },
                introText: {
                    nl: '',
                    fr: '',
                    en: '',
                    public: false
                },
                fullText: {
                    nl: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    fr: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    en: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    public: true
                },
                status: 'Published',
                image: [{
                    url: 'https://www.omnicasa.com/images/logo.svg',
                }]
            },
            {
                id: 2,
                span: 4,
                title: {
                    nl: 'Contact us',
                    fr: 'Contact us',
                    en: 'Contact us',
                    public: true
                },
                introText: {
                    nl: '',
                    fr: '',
                    en: '',
                    public: false,
                },
                fullText: {
                    nl: '<div><ul><li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> + 0123 456 789</a></li>' +
                        '<li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> 056 98 09 08</a></li>' +
                        '<li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> 02 318 1616</a></li>' +
                        '<li><a href="mailto:info@omnicasa.com" title="Mail to Omnicasa"><i class="fa-envelope-o"></i> info@omnicasa.com</a></li>' +
                        '<li>Social: <a href="" title="Omnicasa Facebook page"><i class="fab fa-facebook-f"></i></a> <a href="" title="Omnicasa Twitter page"><i class="fab fa-twitter"></i></a> <a href="" title="Omnicasa Instagram page"><i class="fab fa-instagram"></i></a></li>' +
                        '</ul></div>',
                    fr: '<div><ul><li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> + 0123 456 789</a></li>' +
                        '<li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> 056 98 09 08</a></li>' +
                        '<li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> 02 318 1616</a></li>' +
                        '<li><a href="mailto:info@omnicasa.com" title="Mail to Omnicasa"><i class="fa-envelope-o"></i> info@omnicasa.com</a></li>' +
                        '<li>Social: <a href="" title="Omnicasa Facebook page"><i class="fab fa-facebook-f"></i></a> <a href="" title="Omnicasa Twitter page"><i class="fab fa-twitter"></i></a> <a href="" title="Omnicasa Instagram page"><i class="fab fa-instagram"></i></a></li>' +
                        '</ul></div>',
                    en: '<div><ul><li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> + 0123 456 789</a></li>' +
                        '<li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> 056 98 09 08</a></li>' +
                        '<li><a href="tel:+0123456789" title="Call Omnicasa"><i class="fa-phone1"></i> 02 318 1616</a></li>' +
                        '<li><a href="mailto:info@omnicasa.com" title="Mail to Omnicasa"><i class="fa-envelope-o"></i> info@omnicasa.com</a></li>' +
                        '<li>Social: <a href="" title="Omnicasa Facebook page"><i class="fab fa-facebook-f"></i></a> <a href="" title="Omnicasa Twitter page"><i class="fab fa-twitter"></i></a> <a href="" title="Omnicasa Instagram page"><i class="fab fa-instagram"></i></a></li>' +
                        '</ul></div>',
                    public: true
                },
                status: 'Published',
                image: { id: 0, filename: '', originalname: '', url: '', mimetype: '', destination: '', createDate: '2019-07-01T10:59:24.848Z' },
            },
            {
                id: 3,
                span: 2,
                title: {
                    nl: 'Services',
                    fr: 'Services',
                    en: 'Services',
                    public: true
                },
                introText: {
                    nl: '',
                    fr: '',
                    en: '',
                    public: false
                },
                fullText: {
                    nl: '<div><ul>' +
                        '<li><a href="#" title="Service 1">Service 1</a></li>' +
                        '<li><a href="#" title="Service 2">Service 2</a></li>' +
                        '<li><a href="#" title="Service 3">Service 3</a></li>' +
                        '<li><a href="#" title="Service 4">Service 4</a></li>' +
                        '</ul></div>',
                    fr: '<div><ul>' +
                        '<li><a href="#" title="Service 1">Service 1</a></li>' +
                        '<li><a href="#" title="Service 2">Service 2</a></li>' +
                        '<li><a href="#" title="Service 3">Service 3</a></li>' +
                        '<li><a href="#" title="Service 4">Service 4</a></li>' +
                        '</ul></div>',
                    en: '<div><ul>' +
                        '<li><a href="#" title="Service 1">Service 1</a></li>' +
                        '<li><a href="#" title="Service 2">Service 2</a></li>' +
                        '<li><a href="#" title="Service 3">Service 3</a></li>' +
                        '<li><a href="#" title="Service 4">Service 4</a></li>' +
                        '</ul></div>',
                    public: true
                },
                status: 'Published',
                image: { id: 0, filename: '', originalname: '', url: '', mimetype: '', destination: '', createDate: '2019-07-01T10:59:24.848Z' },
            }
        ],
        copyright: '<a style="color: #fff" href="https://www.omnicasa.com/" target="_blank">© Powered by Omnicasa</a>',
        maxWidth: false
    },
    contact: {
        list: [
            {
                desktopSpan: 6,
                tabletSpan: 6,
                mobileSpan: 12,
                content: {
                    nl: '<div>' +
                    '<h2>Address</h2>' +
                    '<ul style="list-style: none;padding-left: 0;">' +
                    '<li><i class="fa-map-marked-alt"></i> Avenue Louise 523, 1050 Bruxelles</li>' +
                    '<li><i class="fa-phone"></i> <a href="tel:056 98 09 08 / 02 318 16 16" title="Call us">056 98 09 08 / 02 318 16 16</a> / <a href="tel:056 98 09 08 / 02 318 16 16" title="Call us">056 98 09 08 / 02 318 16 16</a></li>' +
                    '<li><i class="fa-envelope"></i> <a href="mailto:info@omnicasa.com" title="Contact us via email">info@omnicasa.com</a></li>' +
                    '<li>BE 123456789</li>' +
                    '</ul>' +
                    '</div>',
                    fr: '<div>' +
                    '<h2>Address</h2>' +
                    '<ul style="list-style: none;padding-left: 0;">' +
                    '<li><i class="fa-map-marked-alt"></i> Avenue Louise 523, 1050 Bruxelles</li>' +
                    '<li><i class="fa-phone"></i> <a href="tel:056 98 09 08 / 02 318 16 16" title="Call us">056 98 09 08 / 02 318 16 16</a> / <a href="tel:056 98 09 08 / 02 318 16 16" title="Call us">056 98 09 08 / 02 318 16 16</a></li>' +
                    '<li><i class="fa-envelope"></i> <a href="mailto:info@omnicasa.com" title="Contact us via email">info@omnicasa.com</a></li>' +
                    '<li>BE 123456789</li>' +
                    '</ul>' +
                    '</div>',
                    en: '<div>' +
                    '<h2>Address</h2>' +
                    '<ul style="list-style: none;padding-left: 0;">' +
                    '<li><i class="fa-map-marked-alt"></i> Avenue Louise 523, 1050 Bruxelles</li>' +
                    '<li><i class="fa-phone"></i> <a href="tel:056 98 09 08 / 02 318 16 16" title="Call us">056 98 09 08 / 02 318 16 16</a> / <a href="tel:056 98 09 08 / 02 318 16 16" title="Call us">056 98 09 08 / 02 318 16 16</a></li>' +
                    '<li><i class="fa-envelope"></i> <a href="mailto:info@omnicasa.com" title="Contact us via email">info@omnicasa.com</a></li>' +
                    '<li>BE 123456789</li>' +
                    '</ul>' +
                    '</div>',
                }
            },
            {
                desktopSpan: 6,
                tabletSpan: 6,
                mobileSpan: 12,
                content: {
                    nl: '<div>' +
                    '<h2>Opening hours</h2>' +
                    '<p>Monday - Friday : from 10:00 to 13:00 and from 14:00 to 19:00</p>' +
                    '<p>Saturday : on appointment</p>' +
                    '</div>',
                    fr: '<div>' +
                    '<h2>Opening hours</h2>' +
                    '<p>Monday - Friday : from 10:00 to 13:00 and from 14:00 to 19:00</p>' +
                    '<p>Saturday : on appointment</p>' +
                    '</div>',
                    en: '<div>' +
                    '<h2>Opening hours</h2>' +
                    '<p>Monday - Friday : from 10:00 to 13:00 and from 14:00 to 19:00</p>' +
                    '<p>Saturday : on appointment</p>' +
                    '</div>',
                }
            },
        ],
        showMap: true,
        maps: [
            { GoogleX: 50.8166814, GoogleY: 4.3693493, Info: '<b>Avenue Louise 523</b><br /> 1050 Bruxelles' },
        ]
    },
    propertyList: {
        filter: {
            citySelect: {
                public: true
            },
            typeSelect: {
                public: true
            },
            roomSelect: {
                public: true,
                dataFields: {
                    bedRoom: true,
                    bathRoom: true,
                    showerRoom: true,
                    toilet: true,
                    garage: true,
                },
            },
            priceRangeSlider: {
                public: true
            }
        }
    }
};

export {
    layout
};
