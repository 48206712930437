import React from 'react';
import Layout from '../components/layout';
import ComponentFormater from './component-formater';
import LazyLoad from 'react-lazyload';
import { layout } from '../configuration/layout';
const setting = require('src/data/setting.json');
const siteInfo = setting.find((item: any) => item.name === 'site_info');

interface IPageProps {
  pageContext: any;
}

interface IPageState {
  data: any;
}

class PageTemplate extends React.Component<IPageProps, IPageState> {
  constructor(props: IPageProps) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const vm = this;
    vm.handleCss();
    window.addEventListener('message', function(event) {
      vm.setState({
        data: JSON.parse(event.data),
      });
    });
    window.top.postMessage(true, '*');
  }

  handleCss() {
    document.documentElement.style.setProperty('--color-primary', siteInfo.params.sitePrimaryColor);
    document.documentElement.style.setProperty('--color-secondary', siteInfo.params.siteSecondaryColor);
    document.documentElement.style.setProperty('--color-tertiary', siteInfo.params.siteTertiaryColor);
    document.documentElement.style.setProperty('--color-primary-text', siteInfo.params.siteColorPrimaryText);
    document.documentElement.style.setProperty('--color-background', siteInfo.params.siteColorBackGround);
  }

  public render() {
    const { data } = this.state;
    let contentRow = [];
    if (data) {
      contentRow = data.contentRow;
      data.layout = {...layout, ...data.layout};
    }

    return (
      <div style={{pointerEvents: 'none'}}>
        {data ? (
          <Layout config={data.layout} isHomePage={data.isHomePage}>
            <div style={{ minHeight: '466px' }}>
              {contentRow.length > 0 &&
                contentRow.map((item: any, i: number) => {
                  return (
                    <LazyLoad height={400} offset={100} key={i}>
                      <ComponentFormater
                        layout={data.layout}
                        dataModule={item}
                      />
                    </LazyLoad>
                  );
                })}
            </div>
          </Layout>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default PageTemplate;
