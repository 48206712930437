export interface ICustomField {
    value: string;
    prepend: string;
    append: string;
    html: boolean;
    tooltip: string;
    show: boolean;
}

export class CustomField implements ICustomField {
    public value: string;
    public prepend: string;
    public append: string;
    public html: boolean;
    tooltip: string;
    public show: boolean;

    constructor(field: ICustomField) {
        this.value = field.value;
        this.prepend = field.append;
        this.append = field.append;
        this.html = field.html;
        this.tooltip = field.tooltip;
        this.show = field.show;
    }
}